type GTMEvent = 'pmFormSubmission';

interface ICreateGTMEvent {
  event: GTMEvent;
  gaEvent: string;
  gaCategory: string;
  gaAction: string;
  /** @deprecated ikke bruk. */
  gaLabel?: string;
  'Page URL'?: string;
}

export const createGTMEvent = (args: ICreateGTMEvent) => {
  try {
    if (window.dataLayer) {
      let url: string = window.location.href;
      window.dataLayer.push({
        event: args.event,
        gaEvent: args.gaEvent,
        gaCategory: args.gaCategory,
        gaAction: args.gaAction,
        gaLabel: args.gaLabel,
        'Page URL': args['Page URL'] || url,
      });
    }
  } catch (e) {
    console.error(e);
  }
};
