import * as React from 'react';
import { getDiggerData } from '../../utils/tracking/digger';
import { SiteContext } from '../context/SiteContext';

export interface DiggerData {
  zip?: string;
  office?: string;
  broker?: string;
  propertyid?: string;
}

export interface DiggerResponse {
  diggerId: string;
}

export enum DiggerType {
  SALGSOPPGAVE = 'Salgsoppgave',
  VERDIVURDERING = 'Verdivurdering',
  KONTAKT = 'Kontakt',
  FINANSIERING = 'Finansiering'
}

export const useDiggerId = () => {
  const { digger } = React.useContext(SiteContext);

  const submitWithDigger = (
    type: DiggerType,
    data: DiggerData,
    callback: (data?: DiggerResponse) => void
  ) => {
    const diggerData = getDiggerData({
      type: type,
      ...data
    });

    digger
      .formSubmit(diggerData)
      .then((response: { result: { id: string } }) => {
        if (response?.result?.id) {
          if (typeof callback === 'function') {
            callback({ diggerId: response?.result?.id });
          }
        }
      })
      .catch((err: any) => {
        console.error('Digger funket ikke, sender skjema allikevel');
        if (typeof callback === 'function') {
          callback();
        }
      });
  };

  return { submitWithDigger };
};
