/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum IdentifierType {
  BROKER_ID = 'BROKER_ID',
  BROKER_PATH = 'BROKER_PATH',
  OFFICE_ID = 'OFFICE_ID',
  OFFICE_URLTAG = 'OFFICE_URLTAG'
}

export enum LeadType {
  CUSTOM = 'CUSTOM',
  EPOST = 'EPOST',
  FINANSIERING = 'FINANSIERING',
  INTERESSENT = 'INTERESSENT',
  KONTAKT = 'KONTAKT',
  SOLDPRICE = 'SOLDPRICE',
  VERDIVURDERING = 'VERDIVURDERING'
}

export enum PageType {
  GENERELL = 'GENERELL',
  KAMPANJE = 'KAMPANJE',
  KONTOR = 'KONTOR',
  MEGLER = 'MEGLER',
  OBJEKT = 'OBJEKT',
  VISNINGSPAAMELDING = 'VISNINGSPAAMELDING',
  NORDEASELGEKJOPE = 'NORDEASELGEKJOPE'
}

export enum PropertyAttachmentType {
  ds_vedlegg = 'ds_vedlegg',
  vedlegg_salgsoppgave = 'vedlegg_salgsoppgave'
}

export enum PropertyGeolocationUnit {
  km = 'km',
  m = 'm'
}

export enum PropertySaleType {
  BRUKT = 'BRUKT',
  FRITID = 'FRITID',
  FRITIDUTLEIE = 'FRITIDUTLEIE',
  NAERING = 'NAERING',
  NAERINGUTLEIE = 'NAERINGUTLEIE',
  NYBYGG = 'NYBYGG'
}

export enum PropertySortByType {
  DISTANCEASC = 'DISTANCEASC',
  DISTANCEDESC = 'DISTANCEDESC',
  PRICEHIGHLOW = 'PRICEHIGHLOW',
  PRICELOWHIGH = 'PRICELOWHIGH',
  PUBLISHEDNEWOLD = 'PUBLISHEDNEWOLD',
  PUBLISHEDOLDNEW = 'PUBLISHEDOLDNEW',
  RECENTLYSOLD = 'RECENTLYSOLD',
  SIZEHIGHLOW = 'SIZEHIGHLOW',
  SIZELOWHIGH = 'SIZELOWHIGH',
  VIEWINGTHISWEEK = 'VIEWINGTHISWEEK',
  VIEWINGTODAY = 'VIEWINGTODAY',
  VIEWINGTOMORROW = 'VIEWINGTOMORROW'
}

export enum SearchTypes {
  broker = 'broker',
  landingpage = 'landingpage',
  office = 'office',
  property = 'property'
}

export interface BrokerFilter {
  projectOnly?: boolean | null;
}

export interface BrokerInput {
  id?: number | null;
  path?: string | null;
}

export interface Consents {
  delmedutbygger?: boolean | null;
  budvarsel?: boolean | null;
  oppdateringer?: boolean | null;
  tipsomliknende?: boolean | null;
  generellmarkedsforing?: boolean | null;
  skalselgebolig?: boolean | null;
  finansiering?: boolean | null;
  forsikring?: boolean | null;
  kjop?: boolean | null;
  tipsomlignendeoggenerellmarkedsforing?: boolean | null;
}

export interface Filter {
  property?: PropertyFilter | null;
  office?: OfficeFilter | null;
  broker?: BrokerFilter | null;
}

export interface LeadInput {
  leadType: LeadType;
  pageType?: PageType | null;
  mailType?: number | null;
  identifierType?: IdentifierType | null;
  identifier?: string | null;
  diggerId?: string | null;
  wmId?: string | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  zip: string;
  email?: string | null;
  phone?: string | null;
  comment?: string | null;
  company: string | null;
  referer?: string | null;
  consents?: Consents | null;
  listType?: string | null;
  notify?: LeadNotify | null;
  json?: any | null;
}

export interface LeadNotify {
  mail?: LeadNotifyMail | null;
  autoreply?: boolean | null;
}

export interface LeadNotifyMail {
  from: string;
  to: string;
  cc?: string | null;
  bcc?: string | null;
  subject: string;
}

export interface ObjectTypeAggregated {
  key?: string | null;
}

export interface OfficeFilter {
  showAll?: boolean | null;
  projectOnly?: boolean | null;
  zip?: OfficeZip | null;
  geolocation?: OfficeGeolocation | null;
}

export interface OfficeGeolocation {
  lat?: string | null;
  lon?: string | null;
  distance?: number | null;
  unit?: PropertyGeolocationUnit | null;
}

export interface OfficeInput {
  id?: number | null;
  urltag?: string | null;
}

export interface OfficeZip {
  value?: string | null;
  distance?: number | null;
  unit?: PropertyGeolocationUnit | null;
}

export interface PropertyFilter {
  exclusive?: boolean | null;
  geolocation?: PropertyGeolocation | null;
  available?: boolean | null;
  sold?: boolean | null;
  bedrooms?: number | null;
  location?: (PropertySearchLocationItem | null)[] | null;
  subLocations?: (PropertySearchSubLocationItem | null)[] | null;
  objectType?: PropertyFilterObjectType | null;
  objectTypeAggregated?: PropertyFilterObjectTypeAggregated | null;
  saleType?: (PropertySaleType | null)[] | null;
  price?: PropertyFilterPrice | null;
  size?: PropertyFilterSize | null;
  sort?: PropertySortByType | null;
  brokerId?: number | null;
  officeIds?: (number | null)[] | null;
  brokerIds?: (number | null)[] | null;
  urltag?: string | null;
  urltagArr?: (string | null)[] | null;
  includeAccepted?: boolean | null;
}

export interface PropertyFilterObjectType {
  leilighet?: boolean | null;
  enebolig?: boolean | null;
  tomannsbolig?: boolean | null;
  rekkehus?: boolean | null;
  gaardsbruk?: boolean | null;
  bygaard?: boolean | null;
  romibofelleskap?: boolean | null;
  hybel?: boolean | null;
  garasjeparkering?: boolean | null;
  hytte?: boolean | null;
  annet?: boolean | null;
  fritid?: boolean | null;
  tomter?: boolean | null;
  hyttetomt?: boolean | null;
  produksjonindustri?: boolean | null;
}

export interface PropertyFilterObjectTypeAggregated {
  types?: (ObjectTypeAggregated | null)[] | null;
}

export interface PropertyFilterPrice {
  from: number;
  to: number;
}

export interface PropertyFilterSize {
  from: number;
  to: number;
}

export interface PropertyGeolocation {
  lat?: string | null;
  lon?: string | null;
  distance?: number | null;
  unit?: PropertyGeolocationUnit | null;
}

export interface PropertyInput {
  sguid: string;
  attachmentType?: PropertyAttachmentType | null;
  preview?: boolean | null;
  includeInactive?: boolean | null;
  includeAccepted?: boolean | null;
  includeUnits?: boolean | null;
  includeArchived?: boolean | null;
  sold?: boolean | null;
}

export interface PropertySearchLocationItem {
  id?: string | null;
  county?: string | null;
  locations?: (PropertySearchSubLocationItem | null)[] | null;
}

export interface PropertySearchSubLocationItem {
  id?: string | null;
  county?: string | null;
  municipalityArea?: string | null;
}

export interface SearchInput {
  searchIn: (SearchInputType | null)[];
  query?: string | null;
  filter?: Filter | null;
}

export interface SearchInputType {
  type: SearchTypes;
  pagination: SearchPagination;
}

export interface SearchPagination {
  limit?: number | null;
  page?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
