import { gql } from '@apollo/client';

export const GENERAL_VALUATION_MUTATION = gql`
  mutation GeneralValuation($input: LeadInput!) {
    lead(input: $input) {
      valuation {
        success
        data {
          id
          address
          zip
          phone
          email
        }
      }
    }
  }
`;
